<template>
    <div>
        <NavigationBar/>
        <div class="fs-services">
             <div class="fs-page-header">
                <h1>Υπηρεσίες</h1>
             </div>

             <div class="fs-services-list">
                <div class="fs-service-widget">
                    <div class="fs-service-icon">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M56 5.33333H8C5.06667 5.33333 2.66667 7.73333 2.66667 10.6667V53.3333C2.66667 56.2667 5.06667 58.6667 8 58.6667H56C58.9333 58.6667 61.3333 56.2667 61.3333 53.3333V10.6667C61.3333 7.73333 58.9333 5.33333 56 5.33333ZM29.3333 29.3333H24V33.3333C24 36.2667 21.6 38.6667 18.6667 38.6667C21.6 38.6667 24 41.0667 24 44V48H29.3333V53.3333H24C21.0667 53.3333 18.6667 50.9333 18.6667 48V46.6667C18.6667 43.7333 16.2667 41.3333 13.3333 41.3333V36C16.2667 36 18.6667 33.6 18.6667 30.6667V29.3333C18.6667 26.4 21.0667 24 24 24H29.3333V29.3333ZM50.6667 41.3333C47.7333 41.3333 45.3333 43.7333 45.3333 46.6667V48C45.3333 50.9333 42.9333 53.3333 40 53.3333H34.6667V48H40V44C40 41.0667 42.4 38.6667 45.3333 38.6667C42.4 38.6667 40 36.2667 40 33.3333V29.3333H34.6667V24H40C42.9333 24 45.3333 26.4 45.3333 29.3333V30.6667C45.3333 33.6 47.7333 36 50.6667 36V41.3333ZM56 18.6667H8V10.6667H56V18.6667Z"/>
                        </svg>
                    </div>
                    <div class="fs-service-details">
                        <h3 class="underline">Ανάπτυξη κάθετων λύσεων</h3>
                        <span>Ανάπτυξη αυτόνομων, κάθετων εφαρμογών ανάλογα με τις προκύπτουσες ανάγκες ανεξαρτήτως εμπορικής εφαρμογής</span>
                    </div>
                </div>
                <div class="fs-service-widget">
                    <div class="fs-service-icon">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7325 3.1785C31.5148 2.71828 32.4852 2.71828 33.2676 3.1785L55.9342 16.5118C56.6978 16.961 57.1667 17.7808 57.1667 18.6667V45.3333C57.1667 46.2192 56.6978 47.039 55.9342 47.4882L33.2676 60.8215C32.4852 61.2817 31.5148 61.2817 30.7325 60.8215L8.06579 47.4882C7.30219 47.039 6.83334 46.2192 6.83334 45.3333V18.6667C6.83334 17.7808 7.30219 16.961 8.06579 16.5118L30.7325 3.1785ZM11.8333 20.0965V43.9035L32 55.7662L52.1667 43.9035V20.0965L32 8.23379L11.8333 20.0965Z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.7799 24.0055C45.5118 25.1763 45.1559 26.7187 43.9851 27.4506L34.5 33.3797V44C34.5 45.3807 33.3807 46.5 32 46.5C30.6193 46.5 29.5 45.3807 29.5 44V33.3905L20.0071 27.4499C18.8367 26.7175 18.4817 25.1749 19.2141 24.0045C19.9465 22.834 21.4891 22.479 22.6595 23.2114L31.9914 29.0513L41.3349 23.2108C42.5057 22.4789 44.0481 22.8347 44.7799 24.0055Z"/>
                        </svg>
                    </div>
                    <div class="fs-service-details">
                        <h3 class="underline">Ανάπτυξη custom λύσεων</h3>
                        <span>Ανάπτυξη custom modules/εφαρμογών οι οποίες "επεκτείνουν" τις λειτουργίες του εμπορικού σας προγράμματος προσθέτοντας νέες δυνατότητες</span>
                    </div>
                </div>
                <div class="fs-service-widget">
                    <div class="fs-service-icon">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M49.3333 10.3333H6.66667V5H49.3333V10.3333ZM46.6667 26.3333C41.5733 26.3333 36.9867 28.36 33.6267 31.6667H33.3333V31.96C30.0267 35.32 28 39.9067 28 45C28 45.9067 28.08 46.7867 28.2133 47.6667H6.66667V31.6667H4V26.3333L6.66667 13H49.3333L52 26.3333V27.1333C50.32 26.6267 48.5333 26.3333 46.6667 26.3333ZM28 31.6667H12V42.3333H28V31.6667ZM46.56 26.3333L44.96 18.3333H11.04L9.44 26.3333H46.56ZM59.4667 48.7333C59.7333 48.7333 59.7333 49 59.4667 49.2667L56.8 53.8C56.5333 54.0667 56.2667 54.0667 56 54.0667L52.8 53C52 53.5333 51.4667 53.8 50.6667 54.3333L50.1333 57.8C50.1333 58.0667 49.8667 58.3333 49.6 58.3333H44.2667C44 58.3333 43.7333 58.0667 43.4667 57.8L42.9333 54.3333C42.1333 54.0667 41.3333 53.5333 40.8 53L37.6 54.3333C37.3333 54.3333 37.0667 54.3333 36.8 54.0667L34.1333 49.5333C33.8667 49.2667 34.1333 49 34.4 48.7333L37.3333 46.6V43.9333L34.4 41.8C34.1333 41.5333 34.1333 41.2667 34.1333 41L36.8 36.4667C37.0667 36.2 37.3333 36.2 37.6 36.2L40.8 37.5333C41.6 37 42.1333 36.7333 42.9333 36.2L43.4667 32.7333C43.4667 32.4667 43.7333 32.2 44.2667 32.2H49.6C49.8667 32.2 50.1333 32.4667 50.1333 32.7333L50.6667 36.2C51.4667 36.4667 52.2667 37 53.0667 37.5333L56.2667 36.2C56.5333 36.2 57.0667 36.2 57.0667 36.4667L59.7333 41C60 41.2667 59.7333 41.5333 59.4667 41.8L56.5333 43.9333V46.6L59.4667 48.7333ZM50.6667 45C50.6667 42.8667 48.8 41 46.6667 41C44.5333 41 42.6667 42.8667 42.6667 45C42.6667 47.1333 44.5333 49 46.6667 49C48.8 49 50.6667 47.1333 50.6667 45Z"/>
                        </svg>
                    </div>
                    <div class="fs-service-details">
                        <h3 class="underline">Πλήρης εγκατάσταση/παραμετροποίηση</h3>
                        <span>Αναλαμβάνουμε την πλήρη εγκατάσταση της εκάστοτε λύσης στους τερματικούς σταθμούς του τελικού πελάτη καθώς και την αρχική παραμετροποίηση της εφαρμογής</span>
                    </div>
                </div>
                <div class="fs-service-widget">
                    <div class="fs-service-icon">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 5.33334C17.296 5.33334 5.33333 17.296 5.33333 32V43.048C5.33333 45.7787 7.72533 48 10.6667 48H13.3333C14.0406 48 14.7189 47.7191 15.219 47.219C15.719 46.7189 16 46.0406 16 45.3333V31.6187C16 30.9114 15.719 30.2331 15.219 29.733C14.7189 29.233 14.0406 28.952 13.3333 28.952H10.912C12.3947 18.632 21.2747 10.6667 32 10.6667C42.7253 10.6667 51.6053 18.632 53.088 28.952H50.6667C49.9594 28.952 49.2811 29.233 48.781 29.733C48.2809 30.2331 48 30.9114 48 31.6187V48C48 50.9413 45.608 53.3333 42.6667 53.3333H37.3333V50.6667H26.6667V58.6667H42.6667C48.5493 58.6667 53.3333 53.8827 53.3333 48C56.2747 48 58.6667 45.7787 58.6667 43.048V32C58.6667 17.296 46.704 5.33334 32 5.33334Z"/>
                        </svg>
                    </div>
                    <div class="fs-service-details">
                        <h3 class="underline">Εκπαίδευση και υποστήριξη</h3>
                        <span>Λεπτομερής εκπαίδευση του προσωπικού πάνω στις λειτουργίες της εφαρμογής αλλά και συνεχής τεχνική υποστήριξη για οποιαδήποτε νέα ανάγκη τυχόν προκύψει</span>
                    </div>
                </div>
               
             </div>           
        </div>

    </div>
</template>
<script>
 import NavigationBar from '@/components/NavigationBar.vue';

export default {
  name: 'Services',
   components: {   
    NavigationBar
  }
}
</script>
<style scoped>

.fs-services {
    min-height:30rem;
}

.fs-page-header {
    width:100%;
    background-image:url("../assets/heroBackground.jpeg");
    background-size:cover;
    background-position: center center;
    background-attachment: fixed;
    padding:var(--container-padding);
}

.fs-page-header h1 {
    color: white;
}

.fs-services-list {
    padding:var(--container-padding);
    display:flex;
    flex-wrap: wrap;
    
}

.fs-service-widget {
    flex-basis:50%;
    padding:4rem;
    border:1px solid #dcdcdc;
}

.fs-service-widget:nth-child(1), .fs-service-widget:nth-child(2) {
    border-top:none;
}

.fs-service-widget:nth-child(1), .fs-service-widget:nth-child(3) {
    border-left:none;
     border-right:none;
}

.fs-service-widget:nth-child(2), .fs-service-widget:nth-child(4) {
    border-right:none;
}

.fs-service-widget:nth-child(3), .fs-service-widget:nth-child(4) {
    border-top:none;
    border-bottom:none;
}

.fs-service-icon {
    text-align:center;
    color:var(--color-primary);
}

.fs-service-details {
    text-align: center;
}

.fs-service-details h3 {
    margin:15px 0;
    color:var(--color-primary);
    font-weight:800;
}

@media screen and (max-width:768px) {
.fs-service-widget {
    flex-basis:100%;
    padding:2rem;
    border-bottom:1px solid #dcdcdc !important;
    border-left:none !important;
    border-right:none !important;
}

 }

</style>

